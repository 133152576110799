<div class="header-bg timeline-container" [style.top.px]="stickyHeaderPos">
    <div class="resources"></div>
    <div class="timeline"></div>
</div>
<div class="timeline-container">
    <div class="resources">
        @for (group of timelineCalendarService.groups(); track group.id; let first = $first) {
            <div>
                <dv-timeline-calendar-group-header
                    [group]="group"
                    [first]="first"
                    [readonly]="readonly"
                    [calendarTranslation]="calendarTranslation"
                    [availableResources]="availableResources"
                    (sortGroup)="sortGroup.emit(group)"
                    (resourceRemove)="resourceRemove.emit($event)"
                    (resourceAdd)="resourceAdd.emit($event)">
                </dv-timeline-calendar-group-header>
            </div>
        }
    </div>

    <div class="timeline" #timelineElem>
        <div #headerElem class="header-times">
            <div class="header-times-container">
                @for (time of timelineCalendarService.grid(); track time.time.valueOf()) {
                    <div [ngStyle]="{'flex-grow': time.durationToNext }"
                         [ngClass]="{
                          'today': isToday(time.time),
                          'week-view': timelineCalendarService.isWeekView(),
                         }"
                         class="time">
                        <div class="header-time"
                             [tooltip]="timelineCalendarService.fullDayBlocks() ? (time.time | dvLocalDateTimeFormat:dayFormat) : (time.time | dvLocalDateTimeFormat:hourFormat) + '\n' + (time.time | dvLocalDateTimeFormat:dayFormat)">
                            @if (timelineCalendarService.fullDayBlocks()) {
                                <a href
                                   [id]="time.time | dvLocalDateTimeFormat:idFormat"
                                   (click)="selectDate.emit(time.time); $event.preventDefault();">
                                    {{ time.time | dvLocalDateTimeFormat:displayFormat() }}
                                </a>
                            }
                            @if (!timelineCalendarService.fullDayBlocks()) {
                                <span>{{ time.time | dvLocalDateTimeFormat:displayFormat() }}</span>
                            }
                        </div>
                        @if (timelineCalendarService.fullDayBlocks() && showDayInfo) {
                            <dv-timeline-calendar-day-info
                                [gridEntry]="time"
                                [readonly]="readonly"
                                [calendarTranslation]="calendarTranslation"
                                (editDayInfoEvent)="editDayInfoEvent.emit($event)">
                            </dv-timeline-calendar-day-info>
                        }
                    </div>
                }
            </div>
        </div>

        @for (group of timelineCalendarService.groups(); track group.id; let first = $first) {
            <div [style.min-width.px]="gridEntryWidth * timelineCalendarService.grid().length"
                 class="group"
                 [attr.data-group-name]="group.getDisplayName()">
                <div class="group-spacer" [ngClass]="{first, 'has-content': group.resources.length > 0}">
                    <dv-timeline-grid [infoBadges]="group.infoBadges"
                                      [grid]="timelineCalendarService.grid"
                                      [noContent]="true"/>
                </div>
                @for (resource of group.resources; track resource.id; let first = $first) {
                    <div class="item-row"
                         [style.height.px]="timelineCalendarService.rowsByGroup()[group.id][resource.id] * rowHeight + 1">

                        <dv-timeline-calendar-resource
                            [group]="group"
                            [resource]="resource"
                            [dragMode]="dragMode"
                            [calendarEventWithOpenedEditOverlay]="calendarEventWithOpenedEditOverlay"
                            [calendarEventEditOverlay]="calendarEventEditOverlay"
                            (closeCalendarEventEditOverlay)="closeCalendarEventEditOverlay.emit()"
                            (resourceDrop)="dropOnResource($event, group, resource)"
                            (resizeEvent)="handleResize($event, resource)"
                            (resizeComplete)="handleResizeComplete($event, resource)"
                            (resourceRemove)="resourceRemove.emit({group, resource})"
                            (deleteEvent)="deleteEvent.emit({event: $event, resource, group})"
                            (editEvent)="editEvent.emit($event)">
                        </dv-timeline-calendar-resource>
                    </div>
                }

                @if (group.extendable) {
                    <div class="group-zuweisen-spacer item-row">
                        <dv-timeline-grid [grid]="timelineCalendarService.grid" [noContent]="true"/>
                    </div>
                }
            </div>
        }
    </div>
</div>
