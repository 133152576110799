/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import type {AfterViewInit, OnInit} from '@angular/core';
import {
    ChangeDetectionStrategy,
    Component,
    computed,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import type {ControlValueAccessor, ValidatorFn} from '@angular/forms';
import {ControlContainer, FormControl, NgControl, NgForm, ReactiveFormsModule} from '@angular/forms';
import {handleResponseError, UserLanguageService} from '@dv/shared/angular';
import type {FunctionType, IPersistable, NamedEntityType, Nullish, SearchResultEntry} from '@dv/shared/code';
import {
    AggragatedEntitySearchTypes,
    checkPresent,
    DvbUtil,
    ENTITY_TO_SEARCH,
    hasOwnPropertyGuarded,
    isNamedEntityType,
    isPresent,
    removeDiacritics,
} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import type {TypeaheadMatch} from 'ngx-bootstrap/typeahead/typeahead-match.class';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    iif,
    map,
    merge,
    mergeMap,
    Observable,
    of,
    Subject,
    switchMap,
    tap,
} from 'rxjs';
import {FavoritService} from '../../../common/service/rest/favoritService';
import {MandantSearchFilter} from '../../model/MandantSearchFilter';
import {SearchService} from '../../service/searchService';
import {SearchEntityResultComponent} from '../search-entity-result/search-entity-result.component';
import {SearchResultIconComponent} from '../search-result-icon/search-result-icon.component';
import {SearchEntitySearchResultDirective} from './search-entity-search-result.directive';

function isSearchResultEntry(obj: unknown): obj is SearchResultEntry {
    return hasOwnPropertyGuarded(obj, 'text') && hasOwnPropertyGuarded(obj, 'id');
}

function isEqual(a: SearchResultEntry | undefined, b: SearchResultEntry | undefined): boolean {
    return a && b ? a.text === b.text && a.id === b.id : a === b;
}

const isValidSearchResultEntry: ValidatorFn = control => {
    return !control.value || isSearchResultEntry(control.value) ?
        null :
        {searchResultEntry: {id: control.value.id, text: control.value.text}};
};

const searchDelay = 250;

@Component({
    selector: 'dv-search-entity',
    standalone: true,
    imports: [
        CommonModule,
        TooltipModule,
        TranslocoModule,
        TypeaheadModule,
        SearchResultIconComponent,
        ReactiveFormsModule,
        SearchEntityResultComponent,
        SearchEntitySearchResultDirective,
    ],
    templateUrl: './search-entity.component.html',
    styleUrl: './search-entity.component.scss',
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchEntityComponent implements ControlValueAccessor, OnInit, AfterViewInit {

    @Input() public entityToSearch?: AggragatedEntitySearchTypes;
    @Input({required: true}) public placeholder!: string;
    @Input() public onSelectClear: boolean = false;
    @Input() public alwaysShowInput: boolean = false;
    @Input() public expandEntity: boolean = false;
    @Input() public filterSource?: (props: { $source: any }) => boolean;
    @Input() public disabledEntries: IPersistable[] = [];
    @Input() public onlyNamed: boolean = false;
    @Input() public mandantFilter?: MandantSearchFilter;
    @Input() public entitiesToSearchFrom: SearchResultEntry[] | undefined;

    @Input()
    public set disabled(isDisabled: boolean) {
        this.setDisabledState(isDisabled);
    }

    @Output() public readonly selectEntity: EventEmitter<SearchResultEntry> = new EventEmitter();

    @ViewChild('textInput') public searchInput?: ElementRef<HTMLInputElement>;

    private favoritesSearchEnabled: boolean = false;
    private entitesToSearch: string = '';
    private locale = inject(UserLanguageService).userLocale;

    public selectSource = new Subject<TypeaheadMatch>();
    private typingSource = new Subject<undefined>();
    private searchTextSource = new Subject<string | Nullish>();

    public ngControl = inject(NgControl, {optional: true, self: true});
    public typeAheadControl = new FormControl<string>('', {nonNullable: true});
    private typeAheadValue = toSignal(this.typeAheadControl.valueChanges, {initialValue: ''});
    public hasText = computed(() => DvbUtil.isNotEmptyString(this.typeAheadValue()));
    private typingChanges$ = this.typeAheadControl.valueChanges.pipe(
        debounceTime(0), // make typing emit after select source (both emit, but in the wrong order)
        filter(val => !this.ngControl || checkPresent(this.ngControl.control).value?.text !== val),
        map(() => undefined),
        takeUntilDestroyed(),
    );
    private change$ = merge(
        this.selectSource.pipe(
            map(match => match?.item),
            filter(isPresent),
            tap(() => {
                if (this.onSelectClear) {
                    this.typeAheadControl.setValue('', {emitEvent: false});
                }
            }),
            filter(value => !value.isDisabled),
        ),
        this.typingSource,
    ).pipe(
        distinctUntilChanged(isEqual),
    );

    private showFavoriten$ = this.searchTextSource.pipe(
        filter(searchText => !searchText || searchText.length === 0),
        filter(() => this.favoritesSearchEnabled && !this.expandEntity),
    );
    private showSearchResults$ = this.searchTextSource.pipe(
        filter(DvbUtil.isNotEmptyString),
    );

    private favoritenResult$ = this.showFavoriten$.pipe(
        map(() => this.entityToSearch),
        filter(isPresent),
        map(entityToSearch => ENTITY_TO_SEARCH[entityToSearch] as NamedEntityType[]),
        mergeMap(types => types.length === 0 ?
            of([]) :
            this.favoritService.getByType$(...types).pipe(
                map(favoriten => favoriten.map(f => f.toSearchResultEntry())),
            ),
        ),
    );

    private inputEntitiesResult$ = this.showSearchResults$.pipe(
        map(searchText => {
            const entities = this.entitiesToSearchFrom ?? [];
            const query = this.localeNormalize(searchText);

            return entities.filter(e =>
                this.localeNormalize(e.text).includes(query) ||
                this.localeNormalize(e.additionalInformation ?? '').includes(searchText),
            );
        }),
    );

    private backendSearchResult$ = this.showSearchResults$.pipe(
        filter(searchText => DvbUtil.isNotEmptyString(searchText.trim())),
        debounceTime(searchDelay),
        switchMap(searchText => {
            const params = {
                entities: this.entitesToSearch,
                expandEntity: this.expandEntity,
            };

            return this.searchService.searchEntity$(searchText, params, this.mandantFilter).pipe(
                catchError(error => {
                    handleResponseError(error);

                    return [];
                }),
            );
        }),
    );

    private searchResult$ = iif(
        () => isPresent(this.entitiesToSearchFrom), this.inputEntitiesResult$, this.backendSearchResult$,
    ).pipe(
        map(results => {
            const filterSource = this.filterSource;

            return !this.expandEntity || !filterSource ?
                results :
                results.filter(entry => filterSource({$source: entry.source}));
        }),
    );

    private typeaheadSource$ = merge(this.favoritenResult$, this.searchResult$).pipe(
        tap(res => this.flagDisabled(res)),
    );

    // the typeahead directive is confusing: it calls this method all the time and just takes 1 value (switchMap like)
    public typeahead$: Observable<SearchResultEntry[]> = new Observable(observer => {
        this.typeaheadSource$.subscribe(observer);
        this.searchTextSource.next(this.typeAheadControl.value);
    });

    public onChange?: (model?: SearchResultEntry) => unknown;
    public onTouched?: FunctionType;

    public constructor(
        private readonly searchService: SearchService,
        private readonly favoritService: FavoritService,
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
        // eagerly load favoriten
        this.favoritService.getAll$().pipe(takeUntilDestroyed()).subscribe();

        this.change$.pipe(
            tap(value => this.onChange?.(value)),
            tap(value => this.selectEntity.emit(value)),
            takeUntilDestroyed(),
        ).subscribe();
    }

    public registerOnChange(fn: FunctionType): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: FunctionType): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.typeAheadControl.disable();
        } else {
            this.typeAheadControl.enable();
        }
    }

    public writeValue(obj?: SearchResultEntry): void {
        this.typeAheadControl.setValue(obj?.text ?? '');
    }

    public ngOnInit(): void {
        this.favoritesSearchEnabled = !this.mandantFilter;

        const entitiesToSearch = this.entityToSearch ?
            ENTITY_TO_SEARCH[this.entityToSearch] || [] :
            Object.values(ENTITY_TO_SEARCH).flat().filter(e => !this.onlyNamed || isNamedEntityType(e));

        this.entitesToSearch = `(${entitiesToSearch.join(',')})`;
    }

    public ngAfterViewInit(): void {
        this.typingChanges$.subscribe(this.typingSource);
        if (this.ngControl) {
            checkPresent(this.ngControl.control).addValidators(isValidSearchResultEntry);
        }
    }

    public getItem(match: TypeaheadMatch): SearchResultEntry {
        return match.item;
    }

    public onRemove(): void {
        this.typeAheadControl.setValue('', {emitEvent: true});
        this.ngControl?.control?.setValue(undefined, {emitEvent: false});
        // wait a change detection cycle until the element is visible
        setTimeout(() => this.searchInput?.nativeElement.focus(), 0);
    }

    private flagDisabled(res: SearchResultEntry[]): void {
        res.forEach(entry => {
            entry.isDisabled = this.disabledEntries.some(d => d.id === entry.id);
        });
    }

    private localeNormalize(text: string): string {
        return removeDiacritics(text.toLocaleLowerCase(this.locale()));
    }
}
