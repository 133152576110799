<dvlib-day-of-week-form [templateRef]="sollzeitTemplate"/>

<ng-template #sollzeitTemplate let-dayOfWeek let-index="index">
    <div [ngModelGroup]="'daily-sollzeit-' + index">
        <label>
            <input type="number"
                   class="form-control input-sm time-input text-center"
                   [placeholder]="'PERSONAL.ANSTELLUNG.SOLLZEIT' | transloco"
                   [tooltip]="'PERSONAL.ANSTELLUNG.DAILY_SOLLZEIT' | transloco"
                   [(ngModel)]="dailyAnstellungen[dayOfWeek].sollzeit"
                   (ngModelChange)="recalculateSumme()"
                   [attr.aria-label]="'PERSONAL.ANSTELLUNG.SOLLZEIT_WITH_DAY' | transloco:{day: dayOfWeek | dvDayOfWeekFormat}"
                   name="dailyHours"
                   min="0"
                   max="24"
                   step="0.01">
        </label>
    </div>
</ng-template>

@if (sollZeitSumme() !== null) {
    <span transloco="PERSONAL.ANSTELLUNG.SOLLZEIT_SUM"
          [translocoParams]="{sum: sollZeitSumme() | number: '1.0-2'}">
    </span>
}
