/*
 * Copyright © 2022 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {downgradeComponent, downgradeInjectable} from '@angular/upgrade/static';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {MandantConfigComponent} from '@dv/kitadmin/mandant-config';
import {DialogService} from '@dv/kitadmin/ui';
import {angularJsUiModule} from '@dv/kitadmin/ui/ajs';
import {
    AdresseDisplayComponent,
    AuthStore,
    DatepickerTextfieldComponent,
    DateSwitcherComponent,
    DeployedVersionsService,
    DisplayNameService,
    ErrorComponent,
    LogoComponent,
    SpinnerComponent,
    translationLoader,
    UserLanguageService,
} from '@dv/shared/angular';
import {OidcService} from '@dv/shared/authentication/oidc';
import {BenutzerService as BenutzerApiService} from '@dv/shared/backend/api/benutzer.service';
import {CheckInCustomFieldService} from '@dv/shared/backend/api/check-in-custom-field.service';
import {KibonRefNrService} from '@dv/shared/backend/api/kibon-ref-nr.service';
import {KindAbweichungsMeldungService} from '@dv/shared/backend/api/kind-abweichungs-meldung.service';
import {KinderEingewoehnungPositionenService} from '@dv/shared/backend/api/kinder-eingewoehnung-positionen.service';
import {KontoService} from '@dv/shared/backend/api/konto.service';
import {NamedEntityService} from '@dv/shared/backend/api/named-entity.service';
import {TerminTypeService} from '@dv/shared/backend/api/termin-type.service';
import {upgradeModule} from '@uirouter/angular-hybrid';
import uiRouter from '@uirouter/angularjs';
import angular from 'angular';
import {OAuthService} from 'angular-oauth2-oidc';

/* eslint-disable import/no-internal-modules,max-lines */
/* @ts-expect-error doesn't find types of deep import */
import buttons from 'angular-ui-bootstrap/src/buttons';
// @ts-expect-error doesn't find types of deep import
import collapse from 'angular-ui-bootstrap/src/collapse';
// @ts-expect-error doesn't find types of deep import
import datepicker from 'angular-ui-bootstrap/src/datepicker/index-nocss';
// @ts-expect-error doesn't find types of deep import
import datepickerPopup from 'angular-ui-bootstrap/src/datepickerPopup/index-nocss';
// @ts-expect-error doesn't find types of deep import
import dropdown from 'angular-ui-bootstrap/src/dropdown/index-nocss';
// @ts-expect-error doesn't find types of deep import
import modal from 'angular-ui-bootstrap/src/modal/index-nocss';
// @ts-expect-error doesn't find types of deep import
import pagination from 'angular-ui-bootstrap/src/pagination';
// @ts-expect-error doesn't find types of deep import
import popover from 'angular-ui-bootstrap/src/popover/index-nocss';
// @ts-expect-error doesn't find types of deep import
import progressbar from 'angular-ui-bootstrap/src/progressbar';
// @ts-expect-error doesn't find types of deep import
import tabs from 'angular-ui-bootstrap/src/tabs';
// @ts-expect-error doesn't find types of deep import
import tooltip from 'angular-ui-bootstrap/src/tooltip/index-nocss';
// @ts-expect-error doesn't find types of deep import
import typeahead from 'angular-ui-bootstrap/src/typeahead/index-nocss';
import {
    AdministrationCustomRechnungPositionFormComponent,
} from './administration/component/administration-custom-rechnung-position-form/administration-custom-rechnung-position-form.component';
import {
    AdministrationMandantRechnungDetailComponent,
} from './administration/component/administration-mandant-rechnung-detail/administration-mandant-rechnung-detail.component';
import {
    AdministrationMandantRechnungsPositionComponent,
} from './administration/component/administration-mandant-rechnungs-position/administration-mandant-rechnungs-position.component';
import {AdministrationService} from './administration/service/administrationService';
import {MaintenanceService} from './administration/service/maintenanceService';
import {AnalyticsService} from './authentication/service/analytics.service';
import {AuthEventService} from './authentication/service/auth-event.service';
import {AuthHttpService} from './authentication/service/auth-http.service';
import {AuthHttpInterceptor} from './authentication/service/authHttpInterceptor';
import {AuthService} from './authentication/service/authService';
import {HttpBuffer} from './authentication/service/httpBuffer';
import {KITADMIN_BROADCAST_SERVICE_TOKEN} from './authentication/service/kitadmin-broadcast-service-token';
import {PrivacyPolicyService} from './authentication/service/privacy-policy.service';
import {AuthorisationService} from './authorisation/service/authorisation.service';
import {AuthorizationProviderJSAdapterService} from './authorisation/service/authorization-provider-jsadapter.service';
import {APP_ROOT_COMPONENT} from './base/component/app-root/dvb-app-root';
import {UserSettingsStore} from './cache/service/cache/userSettingsStore';
import {DisplayTaskComponent} from './common/component/display-task/display-task.component';
import {PensumIconComponent} from './common/component/pensum-icon/pensum-icon.component';
import {SpfInfoComponent} from './common/component/spf-info/spf-info.component';
import {KitAdminAngularJsTranslationInitializer} from './common/i18n/kitAdminAngularJsTranslationInitializer';
import {DebounceService} from './common/service/debounceService';
import {DvbBewerbungsplanService} from './common/service/dvbBewerbungsplanService';
import {DvbStateService} from './common/service/dvbStateService';
import {KindergartenBelegungService} from './common/service/kindergartenBelegungService';
import {LeistungenService} from './common/service/leistungenService';
import {NotificationService} from './common/service/notification/notificationService';
import {AddressService} from './common/service/rest/addressService';
import {AppConfigService} from './common/service/rest/appConfigService';
import {AsyncResponseService} from './common/service/rest/asyncResponseService';
import {BenutzerService} from './common/service/rest/benutzer/benutzerService';
import {GranularKinderOrtPermissionsService} from './common/service/rest/benutzer/granularKinderOrtPermissionsService';
import {KitaPermissionsService} from './common/service/rest/benutzer/kitaPermissionsService';
import {BlobUploadService} from './common/service/rest/blobUploadService';
import {CustomFieldService} from './common/service/rest/customFieldService';
import {DataIntegrityService} from './common/service/rest/dataIntegrityService';
import {DvbFakturaService} from './common/service/rest/dvbFakturaService';
import {FakturaService} from './common/service/rest/fakturaService';
import {FavoritService} from './common/service/rest/favoritService';
import {FirmaService} from './common/service/rest/firmaService';
import {BelegungsService} from './common/service/rest/kind/belegungsService';
import {BetreuungsGutscheinService} from './common/service/rest/kind/betreuungsGutscheinService';
import {BewerbungenService} from './common/service/rest/kind/bewerbungenService';
import {ExtraPlatzService} from './common/service/rest/kind/extraPlatzService';
import {FixPositionenService} from './common/service/rest/kind/fixPositionenService';
import {KindKontaktService} from './common/service/rest/kind/kindKontaktService';
import {KindService} from './common/service/rest/kind/kindService';
import {LeistungsrechnungService} from './common/service/rest/kind/leistungsrechnungService';
import {MonatsBelegungRestService} from './common/service/rest/kind/monatsBelegungRestService';
import {BetreuungsgruendeService} from './common/service/rest/kinderort/betreuungsgruendeService';
import {
    BetreuungsVereinbarungsKonfigurationService,
} from './common/service/rest/kinderort/betreuungsVereinbarungsKonfigurationService';
import {FraktionService} from './common/service/rest/kinderort/fraktionService';
import {KinderOrtSchliesstageService} from './common/service/rest/kinderort/kinderOrtSchliesstageService';
import {KinderOrtService} from './common/service/rest/kinderort/kinderOrtService';
import {KitaBetreuungsfaktorRegelService} from './common/service/rest/kinderort/kitaBetreuungsfaktorRegelService';
import {KitaFakturaService} from './common/service/rest/kinderort/kitaFakturaService';
import {KontaktpersonenZahlungService} from './common/service/rest/kontaktpersonen-zahlung.service';
import {KontaktpersonService} from './common/service/rest/kontaktpersonService';
import {MandantService} from './common/service/rest/mandantService';
import {SmallInvoiceService} from './common/service/rest/smallInvoiceService';
import {TarifService} from './common/service/rest/tarifService';
import {TasksService} from './common/service/rest/tasksService';
import {WochenplanService} from './common/service/rest/wochenplanService';
import {StyleVariableService} from './common/service/style-variable.service';
import {BringAbholZeitenService} from './communication/service/bring-abhol-zeiten-service';
import {BulkEmailService} from './communication/service/bulk-email.service';
import {CommunicationStateService} from './communication/service/communication-state-service';
import {EmailTemplateService} from './communication/service/email-template-service';
import {KontaktKorrespondenzService} from './communication/service/kontakt-korrespondenz-service';
import {SteuerbescheinigungenService} from './communication/service/steuerbescheinigungen-service';
import {DokumentlayoutService} from './dokumentlayout/service/dokumentlayoutService';
import {HttpErrorInterceptor} from './errors/service/httpErrorInterceptor';
import {CustomStyleService} from './external/service/custom-style.service';
import {ExternalAnmeldungConfigService} from './external/service/externalAnmeldungConfigService';
import {ExternalAnmeldungCustomFieldService} from './external/service/externalAnmeldungCustomFieldService';
import {ExternalAnmeldungService} from './external/service/externalAnmeldungService';
import {GoogleTagManagerService} from './external/service/google-tag-manager.service';
import {PublicExternalAnmeldungService} from './external/service/publicExternalAnmeldungService';
import {BankStatementService} from './faktura/service/bankStatementService';
import {FraktionBetreuungsSchluesselService} from './fraktion/service/fraktionBetreuungsSchluesselService';
import {KapazitaetService} from './fraktion/service/kapazitaetService';
import {MonatsBlattApiService} from './fraktion/service/monatsBlattApiService';
import {MonatsBlattService} from './fraktion/service/monatsBlattService';
import {BetreuungAnfrageService} from './kibon/service/betreuungAnfrageService';
import {KibonExchangeBetreuungService} from './kibon/service/kibonExchangeBetreuungService';
import {KibonInstitutionenService} from './kibon/service/kibonInstitutionenService';
import {KibonVerfuegungenService} from './kibon/service/kibonVerfuegungenService';
import {MutationBadgeInfoService} from './kibon/service/mutationBadgeInfoService';
import {RefNrService} from './kibon/service/refNrService';
import {TagesschuleAnmeldungService} from './kibon/service/tagesschuleAnmeldungService';
import {AnwesenheitsSollService} from './kind/anwesenheitssoll/anwesenheits-soll.service';
import {
    EingewoehnungpositionComponent,
} from './kind/leistungen/eingewoehnungposition/eingewoehnungposition/eingewoehnungposition.component';
import {
    KindLeistungenPositionenComponent,
} from './kind/leistungen/kind-leistungen-positionen/kind-leistungen-positionen.component';
import {LeistungspositionComponent} from './kind/leistungen/leistungsposition/leistungsposition.component';
import {AnwesenheitCustomFieldService} from './kind/service/anwesenheitCustomFieldService';
import {BetreuungsVerlaufService} from './kind/service/betreuungsVerlaufService';
import {KindValidationService} from './kind/service/kindValidationService';
import {KindZuweisenService} from './kind/service/kindZuweisenService';
import {MonatsBelegungService} from './kind/service/monatsBelegungService';
import {ZuweisenUtil} from './kind/service/zuweisenUtil';
import {
    DienstAdministrationComponent,
} from './kinderort/component/personal/dienst-administration/dienst-administration.component';
import {PersonalplanungComponent} from './kinderort/component/personal/personalplanung/personalplanung.component';
import {KinderFilterService} from './kinderort/service/kinderFilterService';
import {KinderOrtBetreuungsSchluesselService} from './kinderort/service/kinderOrtBetreuungsSchluesselService';
import {KinderOrtWocheService} from './kinderort/service/kinderOrtWocheService';
import {StundenKontingentService} from './kinderort/service/stundenKontingentService';
import {
    KontaktpersonDepotsComponent,
} from './kontaktperson/component/kontaktperson-depots/kontaktperson-depots.component';
import {MandantConfigurationService} from './mandant/service/mandantConfigurationService';
import {MandantRechnungConfigService} from './mandant/service/mandantRechnungConfigService';
import {NotizService} from './notiz/service/notiz-service';
import {
    AngestellteAnstellungFormComponent,
} from './personal/anstellung/component/angestellte-anstellung-form/angestellte-anstellung-form.component';
import {AngestellteService} from './personal/anstellung/service/angestellteService';
import {AnstellungService} from './personal/anstellung/service/anstellungService';
import {AusbildungService} from './personal/anstellung/service/ausbildungService';
import {KindBetreuungErfassungService} from './personal/anstellung/service/kindBetreuungErfassungService';
import {PersonalBedarfService} from './personal/bedarf/service/personalBedarfService';
import {BetreuungsSchluesselService} from './personal/betreuungs-schluessel/service/betreuungsSchluesselService';
import {PersonalKonfigurationApiService} from './personal/konfiguration/personal-konfiguration-api.service';
import {TerminFormDialogComponent} from './personal/termin/termin-form-dialog/termin-form-dialog.component';
import {TerminFormComponent} from './personal/termin/termin-form/termin-form.component';
import {TerminService} from './personal/termin/terminService';
import {CustomFieldConfigurationService} from './report/service/customFieldConfigurationService';
import {ReportBlobService} from './report/service/reportBlobService';
import {ReportService} from './report/service/reportService';
import {ReportTemplateService} from './report/service/reportTemplateService';
import {SchliesstageService} from './schliesstage/service/schliesstageService';
import {SearchResultIconComponent} from './search/component/search-result-icon/search-result-icon.component';
import {SearchService} from './search/service/searchService';
import {VersionInterceptor} from './version/service/versionInterceptor';
import {
    CustomFieldSortItemComponent,
} from './voreinstellungen/component/custom-field-sort-item/custom-field-sort-item.component';
import {
    TarifParameterSortComponent,
} from './voreinstellungen/component/dvb-tarif-parameter/tarif-parameter-sort.component';
import {BetreuungsZeitraumService} from './wochenplan/service/betreuungsZeitraumService';
import {WysiwygEditorComponent} from './wysiwyg/component/wysiwyg-editor/wysiwyg-editor.component';
/* eslint-enable import/no-internal-modules */

const requires = [
    // Third Party modules
    uiRouter,
    upgradeModule.name,
    'ngSanitize',
    'ngAnimate',
    'angularMoment',
    'pascalprecht.translate',
    'ngFileUpload',
    'angular-toArrayFilter',
    'monospaced.elastic',
    'ui.scroll',
    'ngCookies',
    'ui.tree',
    'ngSentry',

    // Angular UI Bootstrap modules
    buttons,
    collapse,
    datepicker,
    datepickerPopup,
    dropdown,
    modal,
    pagination,
    popover,
    progressbar,
    tabs,
    tooltip,
    typeahead,

    // our modules
    angularJsUiModule.name,
];

export const ANGULAR_JS_MODULE = angular.module('kitAdmin', requires)
    .component('appRoot', APP_ROOT_COMPONENT)
    .factory('kitAdminTranslationLoader', () => (options: { key: string }) => translationLoader(options.key))

    .service('addressService', AddressService)
    .service('administrationService', AdministrationService)
    .service('angestellteService', AngestellteService)
    .service('anstellungService', AnstellungService)
    .service('anwesenheitCustomFieldService', AnwesenheitCustomFieldService)
    .service('appConfigService', AppConfigService)
    .service('asyncResponseService', AsyncResponseService)
    .service('ausbildungService', AusbildungService)
    .service('authHttpInterceptor', AuthHttpInterceptor)
    .service('authService', AuthService)
    .service('bankStatementService', BankStatementService)
    .service('belegungsService', BelegungsService)
    .service('benutzerService', BenutzerService)
    .service('betreuungAnfrageService', BetreuungAnfrageService)
    .service('betreuungsgruendeService', BetreuungsgruendeService)
    .service('betreuungsGutscheinService', BetreuungsGutscheinService)
    .service('betreuungsSchluesselService', BetreuungsSchluesselService)
    .service('betreuungsVereinbarungsKonfigurationService', BetreuungsVereinbarungsKonfigurationService)
    .service('betreuungsVerlaufService', BetreuungsVerlaufService)
    .service('betreuungsZeitraumService', BetreuungsZeitraumService)
    .service('bewerbungenService', BewerbungenService)
    .service('blobUploadService', BlobUploadService)
    .service('bringAbholZeitenService', BringAbholZeitenService)
    .service('communicationStateService', CommunicationStateService)
    .service('customFieldConfigurationService', CustomFieldConfigurationService)
    .service('customFieldService', CustomFieldService)
    .service('dataIntegrityService', DataIntegrityService)
    .service('debounceService', DebounceService)
    .service('dokumentlayoutService', DokumentlayoutService)
    .service('dvbBewerbungsplanService', DvbBewerbungsplanService)
    .service('dvbFakturaService', DvbFakturaService)
    .service('dvbStateService', DvbStateService)
    .service('emailTemplateService', EmailTemplateService)
    .service('externalAnmeldungConfigService', ExternalAnmeldungConfigService)
    .service('externalAnmeldungService', ExternalAnmeldungService)
    .service('externalAnmeldungCustomFieldService', ExternalAnmeldungCustomFieldService)
    .service('extraPlatzService', ExtraPlatzService)
    .service('fakturaService', FakturaService)
    .service('firmaService', FirmaService)
    .service('fixPositionenService', FixPositionenService)
    .service('fraktionBetreuungsSchluesselService', FraktionBetreuungsSchluesselService)
    .service('fraktionService', FraktionService)
    .service('granularKinderOrtPermissionsService', GranularKinderOrtPermissionsService)
    .service('httpBuffer', HttpBuffer)
    .service('httpErrorInterceptor', HttpErrorInterceptor)
    .service('kapazitaetService', KapazitaetService)
    .service('kibonExchangeBetreuungService', KibonExchangeBetreuungService)
    .service('kibonInstitutionenService', KibonInstitutionenService)
    .service('kibonVerfuegungenService', KibonVerfuegungenService)
    .service('kindBetreuungErfassungService', KindBetreuungErfassungService)
    .service('kinderFilterService', KinderFilterService)
    .service('kindergartenBelegungService', KindergartenBelegungService)
    .service('kinderOrtBetreuungsSchluesselService', KinderOrtBetreuungsSchluesselService)
    .service('kinderOrtSchliesstageService', KinderOrtSchliesstageService)
    .service('kinderOrtService', KinderOrtService)
    .service('kinderOrtWocheService', KinderOrtWocheService)
    .service('kindKontaktService', KindKontaktService)
    .service('kindService', KindService)
    .service('kindValidationService', KindValidationService)
    .service('kindZuweisenService', KindZuweisenService)
    .service('kitaBetreuungsfaktorRegelService', KitaBetreuungsfaktorRegelService)
    .service('kitAdminAngularJsTranslationInitializer', KitAdminAngularJsTranslationInitializer)
    .service('kitaFakturaService', KitaFakturaService)
    .service('kitaPermissionsService', KitaPermissionsService)
    .service('kontaktKorrespondenzService', KontaktKorrespondenzService)
    .service('kontaktpersonService', KontaktpersonService)
    .service('leistungenService', LeistungenService)
    .service('leistungsrechnungService', LeistungsrechnungService)
    .service('maintenanceService', MaintenanceService)
    .service('mandantConfigurationService', MandantConfigurationService)
    .service('mandantService', MandantService)
    .service('monatsBelegungRestService', MonatsBelegungRestService)
    .service('monatsBelegungService', MonatsBelegungService)
    .service('monatsBlattApiService', MonatsBlattApiService)
    .service('monatsBlattService', MonatsBlattService)
    .service('mutationBadgeInfoService', MutationBadgeInfoService)
    .service('notificationService', NotificationService)
    .service('notizService', NotizService)
    .service('personalBedarfService', PersonalBedarfService)
    .service('publicExternalAnmeldungService', PublicExternalAnmeldungService)
    .service('refNrService', RefNrService)
    .service('reportBlobService', ReportBlobService)
    .service('reportService', ReportService)
    .service('reportTemplateService', ReportTemplateService)
    .service('schliesstageService', SchliesstageService)
    .service('smallInvoiceService', SmallInvoiceService)
    .service('steuerbescheinigungenService', SteuerbescheinigungenService)
    .service('stundenKontingentService', StundenKontingentService)
    .service('tagesschuleAnmeldungService', TagesschuleAnmeldungService)
    .service('tarifService', TarifService)
    .service('tasksService', TasksService)
    .service('terminService', TerminService)
    .service('versionInterceptor', VersionInterceptor)
    .service('wochenplanService', WochenplanService)
    .service('zuweisenUtil', ZuweisenUtil)

    // downgrading services
    .factory('analyticsService', downgradeInjectable(AnalyticsService))
    .factory('anwesenheitsSollService', downgradeInjectable(AnwesenheitsSollService))
    .factory('authEventService', downgradeInjectable(AuthEventService))
    .factory('authHttpService', downgradeInjectable(AuthHttpService))
    .factory('authorisationService', downgradeInjectable(AuthorisationService))
    .factory('authorizationProviderJsadapterService', downgradeInjectable(AuthorizationProviderJSAdapterService))
    .factory('authStore', downgradeInjectable(AuthStore))
    .factory('benutzerApiService', downgradeInjectable(BenutzerApiService))
    .factory('broadcastService', downgradeInjectable(KITADMIN_BROADCAST_SERVICE_TOKEN))
    .factory('bulkEmailService', downgradeInjectable(BulkEmailService))
    .factory('checkInCustomFieldService', downgradeInjectable(CheckInCustomFieldService))
    .factory('customStyleService', downgradeInjectable(CustomStyleService))
    .factory('deployedVersionsService', downgradeInjectable(DeployedVersionsService))
    .factory('dialogService', downgradeInjectable(DialogService))
    .factory('displayNameService', downgradeInjectable(DisplayNameService))
    .factory('errorService', downgradeInjectable(ErrorService))
    .factory('favoritService', downgradeInjectable(FavoritService))
    .factory('googleTagManagerService', downgradeInjectable(GoogleTagManagerService))
    .factory('kontoService', downgradeInjectable(KontoService))
    .factory('mandantRechnungConfigService', downgradeInjectable(MandantRechnungConfigService))
    .factory('namedEntityService', downgradeInjectable(NamedEntityService))
    .factory('oauthService', downgradeInjectable(OAuthService))
    .factory('oidcService', downgradeInjectable(OidcService))
    .factory('personalKonfigurationApiService', downgradeInjectable(PersonalKonfigurationApiService))
    .factory('privacyPolicyService', downgradeInjectable(PrivacyPolicyService))
    .factory('searchService', downgradeInjectable(SearchService))
    .factory('styleVariableService', downgradeInjectable(StyleVariableService))
    .factory('userLanguageService', downgradeInjectable(UserLanguageService))
    .factory('userSettingsStore', downgradeInjectable(UserSettingsStore))
    .service('kibonRefNrService', downgradeInjectable(KibonRefNrService))
    .service('kindAbweichungsMeldungService', downgradeInjectable(KindAbweichungsMeldungService))
    .service('kinderEingewoehnungPositionenService', downgradeInjectable(KinderEingewoehnungPositionenService))
    .service('kontaktpersonenZahlungService', downgradeInjectable(KontaktpersonenZahlungService))
    .service('terminTypeService', downgradeInjectable(TerminTypeService))
    // downgrading components
    .directive('dvAdministrationCustomRechnungPositionForm',
        downgradeComponent({component: AdministrationCustomRechnungPositionFormComponent}))
    .directive('dvAdministrationMandantRechnungsPosition',
        downgradeComponent({component: AdministrationMandantRechnungsPositionComponent}))
    .directive('dvAdministrationMandantRechnungDetail',
        downgradeComponent({component: AdministrationMandantRechnungDetailComponent}))
    .directive('dvAngestellteAnstellungForm', downgradeComponent({component: AngestellteAnstellungFormComponent}))
    .directive('dvCustomFieldSortItem', downgradeComponent({component: CustomFieldSortItemComponent}))
    .directive('dvDienstAdministration', downgradeComponent({component: DienstAdministrationComponent}))
    .directive('dvDisplayTask', downgradeComponent({component: DisplayTaskComponent}))
    .directive('dvEingewoehnungposition', downgradeComponent({component: EingewoehnungpositionComponent}))
    .directive('dvKindLeistungenPositionen', downgradeComponent({component: KindLeistungenPositionenComponent}))
    .directive('dvKontaktpersonDepots', downgradeComponent({component: KontaktpersonDepotsComponent}))
    .directive('dvLeistungsposition', downgradeComponent({component: LeistungspositionComponent}))
    .directive('dvlibAdresseDisplay', downgradeComponent({component: AdresseDisplayComponent}))
    .directive('dvlibDatepickerTextfield', downgradeComponent({component: DatepickerTextfieldComponent}))
    .directive('dvlibDateSwitcher', downgradeComponent({component: DateSwitcherComponent}))
    .directive('dvlibError', downgradeComponent({component: ErrorComponent}))
    .directive('dvlibLogo', downgradeComponent({component: LogoComponent}))
    .directive('dvlibSpinner', downgradeComponent({component: SpinnerComponent}))
    .directive('dvMandantConfig', downgradeComponent({component: MandantConfigComponent}))
    .directive('dvPensumIcon', downgradeComponent({component: PensumIconComponent}))
    .directive('dvPersonalplanung', downgradeComponent({component: PersonalplanungComponent}))
    .directive('dvSearchResultIcon', downgradeComponent({component: SearchResultIconComponent}))
    .directive('dvSpfInfo', downgradeComponent({component: SpfInfoComponent}))
    .directive('dvTarifParameterSort', downgradeComponent({component: TarifParameterSortComponent}))
    .directive('dvTerminForm', downgradeComponent({component: TerminFormComponent}))
    .directive('dvTerminFormDialog', downgradeComponent({component: TerminFormDialogComponent}))
    .directive('dvWysiwygEditor', downgradeComponent({component: WysiwygEditorComponent}))
;
// eslint-disable-next-line max-lines
