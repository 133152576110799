<div class="calendar-resource-container timeline-container"
     [ngClass]="{'drag-mode': dragMode}"
     [attr.data-resource-id]="resource.id"
     [attr.data-resource-name]="resource.getDisplayName()">
    <div class="timeline" [ngStyle]="{height: timelineHeight()}" #timeline (drop)="resourceDrop.emit($event)">
        <dv-timeline-grid [grid]="timelineCalendarService.grid"/>
        @for (layer of timelineCalendarService.layers(); track layer; let i = $index) {
            <div class="timeline-layer"
                 [ngClass]="'layer-' + i">

                @for (row of timelineCalendarService.eventsByGroup()[group.id][resource.id][layer] | keyvalue; track row.key) {
                    <div class="event-row">
                        <dv-timeline-events [events]="row.value"
                                            [calendarEventWithOpenedEditOverlay]="calendarEventWithOpenedEditOverlay"
                                            [calendarEventEditOverlay]="calendarEventEditOverlay"
                                            (closeCalendarEventEditOverlay)="closeCalendarEventEditOverlay.emit()"
                                            (resizeEvent)="onResize($event, layer)"
                                            (resizeComplete)="resizeComplete.emit($event)"
                                            (deleteEvent)="deleteEvent.emit($event)"
                                            (editEvent)="editEvent.emit($event)">
                        </dv-timeline-events>
                    </div>
                }
            </div>
        }
    </div>
</div>
