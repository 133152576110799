/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed, inject} from '@angular/core';
import {withAccordionFunctionality} from '@dv/kitadmin/ui';
import {handleResponse, idFromLocation} from '@dv/shared/angular';
import {TerminTypeService} from '@dv/shared/backend/api/termin-type.service';
import {
    WorkTimeControllingAbsenceTypesService,
} from '@dv/shared/backend/api/work-time-controlling-absence-types.service';
import type {JaxTerminType} from '@dv/shared/backend/model/jax-termin-type';
import type {JaxWorkTimeControllingAbsenceType} from '@dv/shared/backend/model/jax-work-time-controlling-absence-type';
import type {Persisted} from '@dv/shared/code';
import {checkPersisted, checkPresent, nameComparator} from '@dv/shared/code';
import {patchState, signalStore, withComputed, withHooks, withMethods, withState} from '@ngrx/signals';
import {removeEntity} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {concatMap, map, pipe, switchMap, tap} from 'rxjs';

type ModelsState = {
    isCreating: boolean;
    createdTypeId: string;
    absenceTypes: JaxWorkTimeControllingAbsenceType[];
};

const initialState: ModelsState = {
    isCreating: false,
    createdTypeId: '',
    absenceTypes: [],
};

export const TerminTypesStore = signalStore(
    withDevtools('terminTypes'),
    withState(initialState),
    withAccordionFunctionality<Persisted<JaxTerminType>>(),
    withComputed(store => ({
        isLoadingOrCreating: computed<boolean>(() => {
            const loading = store.isLoading();
            const creating = store.isCreating();

            return loading || creating;
        }),
    })),
    withMethods((
        store,
        service = inject(TerminTypeService),
        absenceTypesService = inject(WorkTimeControllingAbsenceTypesService),
    ) => ({
        load: rxMethod<void>(pipe(
            tap(() => store.startLoading()),
            switchMap(() => service.getAll$().pipe(
                handleResponse({
                    next: data => store.initWithEntities(data.items.map(checkPersisted)),
                    finalize: () => store.finishLoading(),
                }),
            )),
        )),
        loadAbsenceTypes: rxMethod<void>(pipe(
            switchMap(() => absenceTypesService.getAllTypes$().pipe(
                map(data => data.types.sort(nameComparator)),
                handleResponse({
                    next: absenceTypes => {
                        patchState(store, {absenceTypes});
                    },
                }),
            )),
        )),
        createType: rxMethod<JaxTerminType>(pipe(
            tap(() => patchState(store, {isCreating: true})),
            concatMap(jaxTerminType => service.create$({jaxTerminType}, 'response')
                .pipe(
                    handleResponse({
                        next: response => {
                            const id = idFromLocation(response);
                            patchState(store, {createdTypeId: id});
                            store.disableCreateMode();
                        },
                        finalize: () => patchState(store, {isCreating: false}),
                    }),
                ),
            ),
        )),
        updateType: rxMethod<JaxTerminType>(pipe(
            tap(type => store.setItemIsLoadingTrue(checkPresent(type.id))),
            concatMap(type => {
                const id = checkPresent(type.id);

                return service.update$({terminTypeId: id, jaxTerminType: type}).pipe(
                    handleResponse({
                        next: () => store.update({...store.entityMap()[id].entity, ...type}),
                        finalize: () => store.setItemIsLoadingFalse(id),
                    }),
                );
            }),
        )),
        confirmDelete: rxMethod<void>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            concatMap(() => service._delete$({terminTypeId: store.deleteEntity()}).pipe(
                handleResponse({
                    next: () => {
                        patchState(store, removeEntity(store.deleteEntity()));
                        store.hideDeleteDialog();
                    },
                    finalize: () => patchState(store, {isLoading: false}),
                }),
            )),
        )),
    })),
    withHooks({
        onInit(store) {

            // reload whenever a new model got created
            store.load(store.createdTypeId);

            // initially load absence types
            store.loadAbsenceTypes();
        },
    }),
);
