/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {AsyncPipe} from '@angular/common';
import type {OnChanges, Signal} from '@angular/core';
import {ChangeDetectionStrategy, Component, computed, inject, Input} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';

import {ErrorService} from '@dv/kitadmin/core/errors';
import type {KinderOrt} from '@dv/kitadmin/models';
import {SubmitCancelButtonsComponent} from '@dv/kitadmin/ui';
import {
    ButtonListComponent,
    DatepickerTextfieldComponent,
    DateSwitcherComponent,
    DisplayNamePipe,
    IconComponent,
    LetDirective,
    SpinnerComponent,
    ValuesPipe,
} from '@dv/shared/angular';
import {displayableComparator, DvbRestUtil, Persisted} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import {UIRouterModule} from '@uirouter/angular';
import moment from 'moment';
import {ANGESTELLTE_PROFIL_STATE} from '../../../../../personal/anstellung/anstellung-states';
import type {Angestellte} from '../../../../../personal/anstellung/models/Angestellte';
import {PersonalplanungEmailStore} from './personalplanung-email.store';

@Component({
    selector: 'dv-personalplanung-email-form',
    standalone: true,
    imports: [
        TranslocoModule,
        DateSwitcherComponent,
        SubmitCancelButtonsComponent,
        FormsModule,
        DatepickerTextfieldComponent,
        LetDirective,
        UIRouterModule,
        AsyncPipe,
        SpinnerComponent,
        ValuesPipe,
        IconComponent,
        DisplayNamePipe,
        ButtonListComponent,
    ],
    templateUrl: './personalplanung-email-form.component.html',
    styleUrl: './personalplanung-email-form.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonalplanungEmailFormComponent implements OnChanges {

    @Input({required: true}) public kinderOrt!: Persisted<KinderOrt>;
    @Input() public gueltigAb?: moment.Moment;
    @Input() public gueltigBis?: moment.Moment;

    public emailStore = inject(PersonalplanungEmailStore);

    public readonly angestellteState = ANGESTELLTE_PROFIL_STATE;
    public selectedRecipients: { [key: string]: boolean } = {};
    public recipients: Signal<Angestellte[]> = computed(() => {
        const recipients = this.emailStore.recipientInfo().recipients
            .sort(displayableComparator);
        recipients.forEach(recipient => {
            this.selectedRecipients[recipient.entityId()] = true;
        });

        return recipients;
    });
    public springerRecipients: Signal<Angestellte[]> = computed(() => {
        return this.recipients().filter(recipient => recipient.springer);
    });
    public missingEmailRecipients: Signal<Angestellte[]> = computed(() => {
        return this.emailStore.recipientInfo().missingEmail;
    });

    public constructor(
        private readonly errorService: ErrorService,
    ) {
        this.emailStore.sendStore.request$.pipe(takeUntilDestroyed()).subscribe();
    }

    public ngOnChanges(): void {
        this.fetchRecipients();
    }

    public fetchRecipients(): void {
        this.emailStore.fetchRecipientsStore.source$.next({
            kinderOrtId: this.kinderOrt.id,
            kinderOrtIdMatrix: {
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(this.gueltigAb),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(this.gueltigBis),
            },
        });
    }

    public submitForm(form: NgForm): void {
        const selectedRecipientIds = this.recipients()
            .map(recipient => recipient.entityId())
            .filter(id => this.selectedRecipients[id]);

        const isValid = !!form.valid && selectedRecipientIds.length > 0;

        this.errorService.handleValidationError(isValid, 'ERRORS.ERR_INCOMPLETE_FORM');
        if (!isValid) {
            return;
        }

        this.emailStore.sendStore.source$.next({
            kinderOrtId: this.kinderOrt.id,
            kinderOrtIdMatrix: {
                gueltigAb: DvbRestUtil.momentToLocalDateChecked(this.gueltigAb),
                gueltigBis: DvbRestUtil.momentToLocalDateChecked(this.gueltigBis),
            },
            jaxPersonalplanungEmailRecipients: {
                entityIds: selectedRecipientIds,
            },
        });
    }

    public toggleSelection(toggleOnlySpringer: boolean): void {
        const recipients = toggleOnlySpringer ? this.springerRecipients() : this.recipients();
        const selectAll = recipients.some(a => !this.selectedRecipients[a.entityId()]);

        recipients.forEach(recipient => {
            this.selectedRecipients[recipient.entityId()] = selectAll;
        });
    }
}
