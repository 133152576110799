<h2>{{ titleKey() | transloco }}</h2>
<form #form="ngForm" (ngSubmit)="submitForm(form)">
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   name="name"
                   [(ngModel)]="name"
                   [maxlength]="255"
                   [placeholder]="'PERSONAL.TERMIN.TYPE.NAME' | transloco"
                   [tooltip]="'PERSONAL.TERMIN.TYPE.NAME' | transloco"
                   [attr.aria-label]="'PERSONAL.TERMIN.TYPE.NAME' | transloco">
        </div>
    </div>
    <div class="row normal-abstand-bottom">
        <div class="col-md-6" *dvlibRequirePermission="'module:work_time_controlling'">
            <fieldset>
                <legend><h3>{{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_TYPE_TITLE' | transloco }}</h3></legend>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               name="relevantForWorkTimeControlling"
                               value="true"
                               [(ngModel)]="relevantForWorkTimeControlling">
                        {{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANT' | transloco }}
                    </label>
                </div>
                <fieldset
                    [attr.aria-label]="'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANCE_LABEL' | transloco">
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   name="workTimeType"
                                   [(ngModel)]="workTimeControllingRelevance"
                                   [value]="'WORK_TIME'">
                            {{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANCE.WORK_TIME' | transloco }}
                        </label>
                    </div>
                    <div class="radio">
                        <label>
                            <input type="radio"
                                   name="workTimeType"
                                   [(ngModel)]="workTimeControllingRelevance"
                                   [value]="'ABSENCE'">
                            {{ 'PERSONAL.TERMIN.TYPE.WORK_TIME_CONTROLLING_RELEVANCE.ABSENCE' | transloco }}
                        </label>
                    </div>
                </fieldset>

                @if (showAbsenceTypeSelection()) {
                    <select name="absenceType"
                            class="form-control normal-abstand-top"
                            [tooltip]="'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | transloco"
                            [(ngModel)]="absenceTypeId">
                        <option [ngValue]="null"
                                selected>{{ 'PERSONAL.TERMIN.TYPE.CHOOSE_ABSENCE_TYPE' | transloco }}
                        </option>
                        @for (type of absenceTypes(); track type.id) {
                            <option [ngValue]="type.id">{{ type.name }}</option>
                        }
                    </select>
                }
            </fieldset>
        </div>
        <div class="col-md-6">
            <fieldset>
                <legend><h3>{{ 'PERSONAL.TERMIN.TYPE.BEDARFSRECHNUNG_TITLE' | transloco }}</h3></legend>
                <div class="checkbox">
                    <label>
                        <input type="checkbox"
                               name="deductFromBedarfsrechnung"
                               value="true"
                               [(ngModel)]="deductFromBedarfsrechnung">
                        {{ 'PERSONAL.TERMIN.TYPE.DEDUCT_FROM_BEDARFSRECHNUNG' | transloco }}
                    </label>
                </div>
            </fieldset>
        </div>
    </div>

    <dv-submit-cancel-buttons [isLoading]="isLoading()" (cancel)="handleCancel()" />
</form>
